<template>
  <div id="PublicLayout">
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- Logo -->
      <router-link :to="{ name: 'Home' }" class="navbar-brand text-muted">
        <img alt="UPL Logo" src="../assets/upl-logo.jpg" style="height: 50px" height="50" width="66.66" />
        &nbsp;&nbsp; Customer Support
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link to="/create" class="nav-link">
              <b-icon-sticky></b-icon-sticky>
              Open New Ticket
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/my-tickets" class="nav-link">
              <b-icon-stickies></b-icon-stickies>
              My Tickets
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/faq" class="nav-link">
              <b-icon-question-circle></b-icon-question-circle>
              FAQs
            </router-link>
          </li>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <b-icon-box-arrow-in-right></b-icon-box-arrow-in-right>
              Sign in
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <b-icon-journal-text></b-icon-journal-text>
              Sign up
            </router-link>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>



<script>
export default {
  name: "PublicLayout",

  computed: {
    year() {
      let d = new Date();
      return d.getFullYear();
    },
  },
};
</script>
